@tailwind base;
@tailwind components;
@tailwind utilities;

@import "basic";


:root{
	// --cmsLinkColor: theme('colors.brown.DEFAULT');
}

main{
    font-family: 'Noto Sans TC', 'sans-serif';
}
form{
    input, textarea{
        border-radius: 10px;
        &::placeholder{
            color: theme('colors.black.DEFAULT')
        }
    }
    button{
        border-radius: 14px;
    }
}
.radio-button{
    input[type='radio'] {
        display: none;
    }

    input[type='radio']+label {
        position: relative;
        color: #231815;
        padding-left: 22px;
        &:before {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            border: 1px solid #231815;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }
    }

    input[type='radio']:checked+label {
        &:after {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 3px;
            background-color: #231815;
            border-radius: 50%;
        }
    }
}
.contact-form-error{
    position: absolute;
    right: 8px;
    top: 10px;
    color: red;
    font-size: 12px;
}